import React from 'react'
import ReactMarkdown from 'react-markdown'
import Image from 'next/image'
import NextLink from 'next/link'
import { MailOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Row, Col } from 'antd'
import PropTypes from 'prop-types'

const titleFormat = (linkTitle, icon) => (
  <>
    {icon}
    {` ${linkTitle}`}
  </>
)

export default function QuickLinks(props) {
  const { icon, title, linkList, country } = props

  if (linkList < 1 || linkList === 'undefined') return null
  return linkList ? (
    <>
      {country === 'India' && (
        <Row style={{ paddingBottom: '32px' }}>
          <Col style={{ minWidth: '250px' }}>
            <a target="_blank" href="https://bit.ly/2YUNebF" rel="noreferrer">
              <Image
                width={252}
                height={302}
                alt=""
                data-src="https://assets.fintrakk.com/Zerodha-Banner-252x302.png"
                src="https://assets.fintrakk.com/Zerodha-Banner-252x302.png"
              />
            </a>
          </Col>
        </Row>
      )}

      <Card
        // eslint-disable-next-line prettier/prettier
        title={titleFormat(title, icon)}
        size="small"
        bordered={false}
        style={{
          // float: 'right',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: '2px',
          minWidth: '100%',
          marginBottom: '16px',
        }}
        headStyle={{
          background: '#E6F7FF',
          padding: '5px 0px 0px 25px',
        }}
        bodyStyle={{
          padding: '15px 20px 0px 25px',
          minWidth: '272px',
        }}
      >
        {linkList.map((qlink, i) => (
          <div key={i} style={{ marginBottom: '1em' }}>
            <UserOutlined />
            <NextLink href={qlink.href} as={qlink.as}>
              {qlink.linkText}
            </NextLink>
            <br />
            <div style={{ fontSize: '10px' }}>
              {qlink.textDescription !== 'null' ? (
                <ReactMarkdown>{qlink.textDescription}</ReactMarkdown>
              ) : null}
            </div>
          </div>
        ))}

        {/* Add New Link here */}
      </Card>
    </>
  ) : null
}

QuickLinks.defaultProps = {
  icon: <MailOutlined />,
}

QuickLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  country: PropTypes.string,
  linkList: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      as: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    })
  ).isRequired,
}
