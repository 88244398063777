import React from 'react'
import { useRouter } from 'next/router'
import { Row, Col } from 'antd'
import QuickLinks from 'components/shared/QuickLinks'
import { getChildrenToRender } from 'shared/util'

const dataSource = () => {
  return {
    wrapper: { className: 'home-page-wrapper pquestions-wrapper' },
    page: { className: 'home-page pquestions' },
    OverPack: { playScale: 0.3 },
    titleWrapper: {
      className: 'pquestions-title-wrapper',
      children: [
        {
          name: 'title',
          className: 'pquestions-title-h1',
          children: 'Popular Frequently Asked Questions or FAQs',
        },
        {
          name: 'content',
          className: 'pquestions-title-content',
          children: 'What People Ask',
        },
      ],
    },
  }
}

function PopularQuestions(props) {
  const { articles } = props
  const dataSourceWithBrokers = dataSource()
  const { titleWrapper, wrapper, page } = dataSourceWithBrokers
  const {
    investment_faqs,
    finance_faqs,
    stock_market_faqs,
    ipo_basics_faqs,
    option_trading_faqs,
    mutual_funds_faqs,
    stock_trading_faqs,
    financial_terms_faqs,
    trading_software_faqs,
    stock_broker_faqs,
    banking_faqs,
    tfsa_faqs,
    rrsp_faqs,
    cpp_faqs,
    resp_faqs,
    credit_card_faqs,
    new_immigrant_faqs,
    mortgage_faqs,
    crypto_currency_faqs,
    real_estate_faqs,
  } = articles || {}
  const investmentFaqs = []
  const financeFaqs = []
  const stockMarketFaqs = []
  const ipoBasicsFaqs = []
  const financialTermsFaqs = []
  const optionTradingFaqs = []
  const stockBrokerFaqs = []
  const mutualFundsFaqs = []
  const stockTradingFaqs = []
  const tradingSoftwareFaqs = []

  const bankingFaqs = []
  const tfsaFaqs = []
  const cppFaqs = []
  const rrspFaqs = []
  const respFaqs = []
  const creditCardFaqs = []
  const newImmigrantFaqs = []
  const mortgageFaqs = []
  const realEstateFaqs = []
  const cryptocurrencyFaqs = []
  const urlObj = (article) => {
    return {
      href: '/article/[slug]',
      as: `/article/${article.slug}`,
      linkText: ` ${article.title}`,
    }
  }

  investment_faqs?.forEach((article) => {
    investmentFaqs.push(urlObj(article))
  })

  finance_faqs?.forEach((article) => {
    financeFaqs.push(urlObj(article))
  })

  stock_market_faqs?.forEach((article) => {
    stockMarketFaqs.push(urlObj(article))
  })

  ipo_basics_faqs?.forEach((article) => {
    ipoBasicsFaqs.push(urlObj(article))
  })

  financial_terms_faqs?.forEach((article) => {
    financialTermsFaqs.push(urlObj(article))
  })

  option_trading_faqs?.forEach((article) => {
    optionTradingFaqs.push(urlObj(article))
  })

  stock_broker_faqs?.forEach((article) => {
    stockBrokerFaqs.push(urlObj(article))
  })

  mutual_funds_faqs?.forEach((article) => {
    mutualFundsFaqs.push(urlObj(article))
  })

  stock_trading_faqs?.forEach((article) => {
    stockTradingFaqs.push(urlObj(article))
  })

  trading_software_faqs?.forEach((article) => {
    tradingSoftwareFaqs.push(urlObj(article))
  })

  banking_faqs?.forEach((article) => {
    bankingFaqs.push(urlObj(article))
  })

  tfsa_faqs?.forEach((article) => {
    tfsaFaqs.push(urlObj(article))
  })

  cpp_faqs?.forEach((article) => {
    cppFaqs.push(urlObj(article))
  })

  rrsp_faqs?.forEach((article) => {
    rrspFaqs.push(urlObj(article))
  })

  resp_faqs?.forEach((article) => {
    respFaqs.push(urlObj(article))
  })

  credit_card_faqs?.forEach((article) => {
    creditCardFaqs.push(urlObj(article))
  })

  new_immigrant_faqs?.forEach((article) => {
    newImmigrantFaqs.push(urlObj(article))
  })

  mortgage_faqs?.forEach((article) => {
    mortgageFaqs.push(urlObj(article))
  })

  real_estate_faqs?.forEach((article) => {
    realEstateFaqs.push(urlObj(article))
  })

  crypto_currency_faqs?.forEach((article) => {
    cryptocurrencyFaqs.push(urlObj(article))
  })

  const router = useRouter()
  const country = router.query.country_slug

  if (country === 'ca') {
    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          <div {...titleWrapper}>
            {titleWrapper.children.map(getChildrenToRender)}
          </div>

          <Row gutter={[150, 56]}>
            <Col xs={24} lg={12}>
              <QuickLinks title="Stock Market" linkList={stockMarketFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="Banking" linkList={bankingFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="Finance" linkList={financeFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="Investment" linkList={investmentFaqs} />
            </Col>

            <Col xs={24} lg={12}>
              <QuickLinks title="TFSA" linkList={tfsaFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="CPP" linkList={cppFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="RRSP" linkList={rrspFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="RESP" linkList={respFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="Credit Cards" linkList={creditCardFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="New Immigrants" linkList={newImmigrantFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="Mortgage" linkList={mortgageFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks title="Real Estate" linkList={realEstateFaqs} />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks
                title="Trading Software"
                linkList={tradingSoftwareFaqs}
              />
            </Col>
            <Col xs={24} lg={12}>
              <QuickLinks
                title="Cryptocurrency"
                linkList={cryptocurrencyFaqs}
              />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
  return (
    <div {...props} {...wrapper}>
      <div {...page}>
        <div {...titleWrapper}>
          {titleWrapper.children.map(getChildrenToRender)}
        </div>

        <Row gutter={[150, 56]}>
          <Col xs={24} lg={12}>
            <QuickLinks title="Investment Faqs" linkList={investmentFaqs} />
          </Col>
          <Col xs={24} lg={12}>
            <QuickLinks title="Finance Faqs" linkList={financeFaqs} />
          </Col>
          <Col xs={24} lg={12}>
            <QuickLinks title="Stock Market Faqs" linkList={stockMarketFaqs} />
          </Col>
          <Col xs={24} lg={12}>
            <QuickLinks title="IPO Basics Faqs" linkList={ipoBasicsFaqs} />
          </Col>

          <Col xs={24} lg={12}>
            <QuickLinks
              title="Financial Terms Faqs"
              linkList={financialTermsFaqs}
            />
          </Col>
          <Col xs={24} lg={12}>
            <QuickLinks
              title="Option Trading Faqs"
              linkList={optionTradingFaqs}
            />
          </Col>
          <Col xs={24} lg={12}>
            <QuickLinks title="Stock Brokers Faqs" linkList={stockBrokerFaqs} />
          </Col>
          <Col xs={24} lg={12}>
            <QuickLinks title="Mutual Funds Faqs" linkList={mutualFundsFaqs} />
          </Col>
          <Col xs={24} lg={12}>
            <QuickLinks
              title="Stock Trading Faqs"
              linkList={stockTradingFaqs}
            />
          </Col>
          <Col xs={24} lg={12}>
            <QuickLinks
              title="Trading Software Faqs"
              linkList={tradingSoftwareFaqs}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PopularQuestions
