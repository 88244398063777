import React from 'react'
import Link from 'next/link'
import { Row, Col, Rate } from 'antd'
import { getChildrenToRender } from 'shared/util'

const dataSource = () => {
  return {
    wrapper: { className: 'home-page-wrapper abovefooter-wrapper' },
    page: { className: 'home-page abovefooter' },
    OverPack: { playScale: 0.3 },
    logo: {
      className: 'abovefooter-logo',
      children: '/static/images/logo.svg',
    },
    titleWrapper: {
      className: 'abovefooter-title-wrapper',
      children: [
        {
          name: 'title',
          className: 'abovefooter-title-h1',
          children: 'Your One Stop Solution to Financial Queries',
        },
      ],
    },
    secondtitleWrapper: {
      className: 'abovefooter-title2-wrapper',
      children: [
        {
          name: 'title',
          className: 'abovefooter-title2-h1',
          children: 'Trusted By Millions of Readers',
        },
      ],
    },
    thirdtitleWrapper: {
      className: 'abovefooter-title3-wrapper',
      children: [
        {
          name: 'title',
          className: 'abovefooter-title3-h1',
          children: 'Explore Our FAQ Section',
        },
      ],
    },
  }
}

function AboveFooter(props) {
  const dataSourceWithBrokers = dataSource()
  const {
    titleWrapper,
    wrapper,
    page,
    logo,
    secondtitleWrapper,
    thirdtitleWrapper,
  } = dataSourceWithBrokers

  return (
    <div {...props} {...wrapper}>
      <div {...page}>
        <div {...titleWrapper}>
          {titleWrapper.children.map(getChildrenToRender)}
        </div>
        <div {...logo}>
          <img width="150px" src={logo.children} alt="img" />
        </div>
        <div {...secondtitleWrapper}>
          {secondtitleWrapper.children.map(getChildrenToRender)}
        </div>
        <div className="abovefooter-ratings">
          <Rate disabled xs={24} value={5} />
        </div>
        <div {...thirdtitleWrapper}>
          {thirdtitleWrapper.children.map(getChildrenToRender)}
        </div>
        <Row type="flex">
          <Col xs={8} lg={6} style={{ textAlign: 'center', padding: '5px' }}>
            {/* <Button type="primary" href="/faqs/stock-market"> */}
            <Link href="/faqs/stock-market">Stock Market FAQ</Link>
            {/* </Button> */}
          </Col>
          <Col xs={8} lg={6} style={{ textAlign: 'center', padding: '5px' }}>
            {/* <Button type="primary" href="/faqs/investment"> */}
            <Link href="/faqs/investment">Investment FAQ</Link>
            {/* </Button> */}
          </Col>
          <Col xs={8} lg={6} style={{ textAlign: 'center', padding: '5px' }}>
            {/* <Button type="primary" href="/faqs/investment"> */}
            <Link href="/faqs/finance">Finance FAQ</Link>
            {/* </Button> */}
          </Col>
          <Col xs={8} lg={6} style={{ textAlign: 'center', padding: '5px' }}>
            {/* <Button type="primary" href="/faqs/investment"> */}
            <Link href="/faqs/investment">Broker FAQ</Link>
            {/* </Button> */}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AboveFooter
