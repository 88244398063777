import React from 'react'
import QueryAutoComplete from 'components/article/QueryAutoComplete'
import Button from 'components/shared/button'
import Texty from 'rc-texty'
import 'rc-texty/assets/index.css'

const Banner = (props) => {
  const { ...currentProps } = props
  const { dataSource } = currentProps
  delete currentProps.dataSource
  delete currentProps.isMobile
  const children = dataSource.textWrapper.children.map((item) => {
    const { name, texty, ...$item } = item
    if (name.match('button')) {
      return (
        <Button type="primary" key={name} {...$item}>
          {item.children}
        </Button>
      )
    }

    return (
      <div key={name} {...$item}>
        {texty ? (
          <Texty type="mask-bottom">{item.children}</Texty>
        ) : (
          item.children
        )}
      </div>
    )
  })
  return (
    <div {...currentProps} {...dataSource.wrapper}>
      <div {...dataSource.textWrapper}>
        {children}
        <QueryAutoComplete />
        <div style={{ color: 'white', height: '32px', margin: '4px' }}>OR</div>
        <Button href="/create-faq" source="ask-query">
          ASK YOUR QUERY
        </Button>
      </div>
    </div>
  )
}
export default Banner
