const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: 'Keep Your Finances on the Right Track',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: 'Financial Knowledge, Tools & Products',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: 'Ask Your Query & Get Answers from Real Experts',
      },
    ],
  },
}

export default Banner30DataSource
