import React, { useState } from 'react'
import { AutoComplete, Input, Row } from 'antd'
import { useLazyQuery } from '@apollo/client'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { ArticleSearchByKeywordDocument } from 'graphql/generated/types'

export default function QueryAutoComplete() {
  const [getData, { data, loading }] = useLazyQuery(
    ArticleSearchByKeywordDocument
  )
  const [openDropDown, setOpenDropDown] = useState(false)
  const router = useRouter()
  const countrySlug = router.query?.country_slug
  let options = []

  if (loading) {
    options = [{ label: <div>loading...</div> }]
  }

  if (data) {
    options = data.articleSearchByKeyword.map((item) => {
      return {
        label: (
          <div style={{ display: 'flex' }}>
            <span>
              <NextLink href="/article/[slug]" as={`/article/${item.slug}`}>
                {item.title}
              </NextLink>
            </span>
          </div>
        ),
      }
    })
  }

  const searchResult = (value) => {
    if (value.length > 2) {
      getData({
        variables: {
          where: countrySlug
            ? { keyword: value, country: countrySlug }
            : { keyword: value },
          limit: 5,
        },
      })
    }
  }

  return (
    <Row style={{ justifyContent: 'center', marginTop: '32px' }}>
      <AutoComplete
        style={{ width: '500px' }}
        onSearch={searchResult}
        options={options}
        open={openDropDown}
        onFocus={() => setOpenDropDown(true)}
        onBlur={() => setOpenDropDown(false)}
      >
        <Input.Search size="large" placeholder="e.g What is cover order?" />
      </AutoComplete>
    </Row>
  )
}
