import React from 'react'
import Link from 'next/link'
import { Comment } from '@ant-design/compatible'
// import dateformat from 'dateformat'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Row, Col } from 'antd'
import { getChildrenToRender } from 'shared/util'

const dataSource = () => {
  return {
    wrapper: { className: 'home-page-wrapper commentSection-wrapper' },
    page: { className: 'home-page commentSection' },
    OverPack: { playScale: 0.3 },
    titleWrapper: {
      className: 'commentSection-title-wrapper',
      children: [
        {
          name: 'title',
          className: 'commentSection-title-h1',
          children: 'Top Reader Comments',
        },
      ],
    },
  }
}

function CommentSection(props) {
  const { comments } = props
  const dataSourceWithBrokers = dataSource()
  const { titleWrapper } = dataSourceWithBrokers
  const truncatedString = (str) => str?.split(' ', 20).join(' ')
  return (
    <div {...dataSourceWithBrokers.wrapper}>
      <div {...dataSourceWithBrokers.page}>
        <div {...dataSourceWithBrokers.titleWrapper}>
          {titleWrapper.children.map(getChildrenToRender)}
        </div>
        <Row gutter={[150, 0]}>
          <Col xs={24} lg={12}>
            <Comment
              content={
                <p id={comments[0].createdAt}>
                  {truncatedString(comments[0].content)}
                  <Link href={`/article/${comments[0].articleSlug}`}>
                    Check Detailed Reviews
                  </Link>
                </p>
              }
              author={<h3>{comments[0].author && comments[0].author.name}</h3>}
              avatar={
                comments[0].author.avatar ? (
                  <Avatar src={comments[0].author.avatar} />
                ) : (
                  <Avatar size={55} icon={<UserOutlined />} />
                )
              }
              // datetime={dateformat(
              //   new Date(JSON.parse(comments[0].createdAt)),
              //   'dddd, mmmm dS, yyyy, h:MM TT'
              // )}
            />
          </Col>
          <Col xs={24} lg={12}>
            <Comment
              content={
                <p id={comments[1].createdAt}>
                  {truncatedString(comments[1].content)}
                  <Link href={`/article/${comments[1].articleSlug}`}>
                    Check Detailed Reviews
                  </Link>
                </p>
              }
              author={<h3>{comments[1].author && comments[1].author.name}</h3>}
              avatar={
                comments[1].author.avatar ? (
                  <Avatar src={comments[1].author.avatar} />
                ) : (
                  <Avatar size={55} icon={<UserOutlined />} />
                )
              }
              // datetime={dateformat(
              //   new Date(JSON.parse(comments[1].createdAt)),
              //   'dddd, mmmm dS, yyyy, h:MM TT'
              // )}
            />
          </Col>

          <Col xs={24} lg={12}>
            <Comment
              content={
                <p id={comments[2].createdAt}>
                  {truncatedString(comments[2].content)}
                  <Link href={`/article/${comments[2].articleSlug}`}>
                    Check Detailed Reviews
                  </Link>
                </p>
              }
              author={<h3>{comments[2].author && comments[2].author.name}</h3>}
              avatar={
                comments[2].author.avatar ? (
                  <Avatar src={comments[2].author.avatar} />
                ) : (
                  <Avatar size={55} icon={<UserOutlined />} />
                )
              }
              // datetime={dateformat(
              //   new Date(JSON.parse(comments[2].createdAt)),
              //   'dddd, mmmm dS, yyyy, h:MM TT'
              // )}
            />
          </Col>
          <Col xs={24} lg={12}>
            <Comment
              content={
                <p id={comments[3]?.createdAt}>
                  {truncatedString(comments[3]?.content)}
                  <Link href={`/article/${comments[3]?.articleSlug}`}>
                    Check Detailed Reviews
                  </Link>
                </p>
              }
              author={
                <h3>{comments[3]?.author && comments[3]?.author.name}</h3>
              }
              avatar={
                comments[3]?.author.avatar ? (
                  <Avatar src={comments[3].author.avatar} />
                ) : (
                  <Avatar size={55} icon={<UserOutlined />} />
                )
              }
              // datetime={dateformat(
              //   new Date(JSON.parse(comments[3].createdAt)),
              //   'dddd, mmmm dS, yyyy, h:MM TT'
              // )}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CommentSection
